<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<template>
	<div class="app">
		<div class="content">
			<img alt="Grupo Babilonia logo" src="./assets/logo.png">
			<HelloWorld /> <!-- Use the HelloWorld component -->
			<video controls class="square-video">
				<source src="/videos/babilonia_promo.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<h1> Podés apoyarnos con cualquier valor a través: </h1>
			<h1> BROU CA: 110612774-00001 </h1>
			<h1> Poner en la descripcion: Casa Cultural Babilonia</h1>
		</div>

	</div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    HelloWorld // Register the HelloWorld component
  }
};
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Makes the container take up the full viewport height */
  margin: 0; /* Reset margin to avoid extra space */
  padding-top: 50px;
}

.content {
  /* Optional: You can add padding or additional styling here */
	margin-top: 450px;
}

.square-video {
  width: 600px;
  height: 600px;
  object-fit: contain;
}

@media (min-width: 320px) {
  .content {
        font-size: small;
  }
        .square-video {
    width: 600px;
    height: 600px;
  }
}

</style>
